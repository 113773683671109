'user strict';

class Device{
  constructor(option){
    const defaults = Object.assign({
      breakpoint: 768
    }, option);
    this.breakpoint = defaults.breakpoint;
    this.observers = [];
    window.addEventListener('resize', this.resize.bind(this));
    window.addEventListener('load', this.resize.bind(this));
  }
  resize(){
    this.is_sp = window.innerWidth < this.breakpoint;
  }
  set is_sp(sp){
    if(this._is_sp !== sp){
      this.emit(sp ? 'sp' : 'pc');
    }
    this._is_sp = sp;
  }
  get is_sp(){
    return this._is_sp;
  }
  emit(handle, ...args){
    if (handle in this.observers) {
      this.observers[handle].forEach(callback => callback(...args));
    }
  }
  on(handle, callback){
    if (!(handle in this.observers)) {
      this.observers[handle] = []
    }
    this.observers[handle].push(callback);
  }
}
export default Device;
