'use strict';

import "core-js/stable";
import "regenerator-runtime/runtime";

const TRANSITION_END = 'webkitTransitionEnd transitionend';
// const BREAK_POINT = 960;
import BREAK_POINT from './global';


require("picturefill");
import 'objectFitPolyfill';
// import "jquery.scrollbar";
// import 'slick-carousel';
// import anime from 'animejs'
// import Rellax from 'rellax';
import ScrollHelper from './scrollhelper.js';
import ResizeHelper from './resize.js';
import SmoothScroll from './smoothScroll.js';
import Intersection from './Intersection.js'
import MV from './MV.js'
import Device from './device.js';
import 'url-polyfill';
import ScrollTrigger from '@terwanerik/scrolltrigger'

const $body = $('body');
const $window = $(window);


const mv = new MV()
if(!document.body.classList.contains('dev')) {
  mv.init()
}

$(() => {

  const $body = $('body');
  const $window = $(window);

  $body.addClass('ready');


  if(!document.body.classList.contains('dev')) {
    mv.start()
  }


  $('.js_service-selector').on('mouseover', e => {
    const target = $(e.currentTarget).attr('data-service')
    $('body').attr('data-service-current', target)
  }).on('mouseleave', e => {
  })

  $('.js_service-enter').on('mouseleave', e => {
    $('body').attr('data-service-current', '')
  })

  $('.js_service-close').on('click', e => {
    $('body').attr('data-service-current', '')
    $('body').attr('data-service-current_sp', '')
  })

  $('.js_service-selector').on('click', e => {
    const target = $(e.currentTarget).attr('data-service')
    $('body').attr('data-service-current_sp', target)
  })
  // $('.js_single-service').on('click', e => {
  //   $('body').attr('data-service-current_sp', '')
  // })

  const videoThumbnails = new Intersection('.js-intersection', entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        document.body.classList.add('is_dark')
        document.body.classList.add('is_dark_once')
      } else {
        document.body.classList.remove('is_dark')
        document.body.classList.remove('is_darked_once')
      }
    })
  }, {
    root: null,
    rootMargin: "-40% 0px -60% 0px",
    threshold: 0
  })


  // スクロールエフェクト --------------------------------------------------
  const trigger = new ScrollTrigger({
    trigger: {
      once: true,
      offset: {
        element: {
          x: 0,
          y: (trigger, rect, direction) => {
            return 0.25
          }
        },
        viewport: {
          x: 0,
          y: (trigger, frame, direction) => {
            return 0.3
          }
        }
      },
    },
  })
  trigger.add('[data-trigger]')


  // menu
  $('.js_open-menu').on('click', () => {
    $body.addClass('menu-active');
  });
  $('.js_toggle-menu').on('click', () => {
    $body.toggleClass('menu-active');
  });
  $('.js_close-menu').on('click', () => {
    $body.removeClass('menu-active');
  });

  // setInterval(e => {
  //   $('.anim_section-title').toggleClass('visible')
  // }, 3500)


  // bodyclass(scrolled)
  let windowHeight = window.innerHeight;
  const resizeHelper = new ResizeHelper(() => {
    windowHeight = window.innerHeight;
  })
  const stickyHeader = new ScrollHelper(function(){
    $body.toggleClass('scrolled', $(window).scrollTop() >= windowHeight);
  });

  // scroll label
  // const scrollSign = new ScrollHelper(() => {
  //   if($(window).scrollTop() >= 50){
  //     scrollSign.destroy();
  //     $('#scroll').css('opacity', 0)
  //   }
  // });

  // -----------------------------------------------------------------------------
  // INDEX


  const $aboutBlocks = $('.about-block');
  if($aboutBlocks.length){
    const rellaxBackgrounds = [];
    const rellaxImages = [];

    $aboutBlocks.each((index, el) => {
      const background = el.querySelector('.block-bg');
      const image = el.querySelector('.block-image');

      rellaxBackgrounds.push(
        new Rellax(background, {
          speed: -1.2,
          center: true,
          // wrapper: el,
          round: true,
          vertical: true,
          horizontal: false
        })
      );
      rellaxImages.push(
        new Rellax(image, {
          // speed: (function(){
          //   return $window.width() < BREAK_POINT ? 0.3 : 2;
          // })(),
          center: true,
          // wrapper: el,
          round: true,
          vertical: true,
          horizontal: false
        })
      );
    });


    const device = new Device({
      breakpoint: BREAK_POINT
    });
    device.on('sp', (e) => {
      rellaxBackgrounds.forEach(rellax => {
        rellax.options.speed = -0.75;
        rellax.refresh();
      });
      rellaxImages.forEach(rellax => {
        rellax.options.speed = 0.3;
        rellax.refresh();
      });
    });
    device.on('pc', (e) => {
      rellaxBackgrounds.forEach(rellax => {
        rellax.options.speed = -1.2;
        rellax.refresh();
      });
      rellaxImages.forEach(rellax => {
        rellax.options.speed = 2;
        rellax.refresh();
      });
    });
  }


  // scroll
  // const smoothScroll = new SmoothScroll({
  //   breakpoint: BREAK_POINT,
  //   offset: {
  //     pc: 0,
  //     sp: 0
  //   }
  // });
  // smoothScroll.begin = () => {
  //   if($window.width() < BREAK_POINT){
  //     $body.removeClass('menu-active');
  //   }
  // }





  objectFitPolyfill();


});
