import lottie from 'lottie-web'

class MV {
  constructor() {
    this.FRAME = 85
    this.logotype = lottie.loadAnimation({
      container: document.getElementById('logotype'), 
      renderer: 'svg', 
      loop: false, 
      autoplay: false,
      path: 'data/data.json',
    })
    this.listen()
  }
  init() {
  }
  listen() {
    this.logotype.addEventListener('enterFrame', e => {
      this.stageChange(e)
    })
    this.logotype.addEventListener('complete', e => {
      this.end()
    })
  }
  start() {
      this.logotype.play()
  }
  stageChange(e) {
    $(window).scrollTop(0)
    if( e.currentTime >= this.FRAME) {
      document.body.classList.add('mv_invert')
      document.getElementsByTagName( 'html' )[0].classList.add('mv_endanimation')
      document.body.classList.add('mv_endanimation')
      this.logotype.removeEventListener('enterFrame')
    }
  }
  end() {
    document.body.classList.add('mv_complete')
  }
}

export default MV;
